var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-org"},[_c('Header'),(!_vm.isLoading)?_c('v-container',{staticClass:"my-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentUser)?_c('div',{staticClass:"d-lg-flex align-center"},[_c('h1',{staticClass:"text-h2 mr-3"},[_vm._v("Org Reports dashboard")])]):_vm._e(),_c('v-divider',{staticClass:"mt-8 mb-3"})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5 mb-15 align-center",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":true,"headers":_vm.headers,"items":_vm.orgReports,"options":_vm.options,"server-items-length":_vm.itemsTotal,"sort-desc":[false, true],"multi-sort":"","show-expand":"","show-select":"","search":_vm.search,"custom-filter":_vm.customDataTableItemsFilter,"footer-props":{
            itemsPerPageOptions: [10]
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.risk",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.final_risk_level),"dark":""}},[_vm._v("Low")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(
                item.status != 'In Progress' &&
                  item.status != 'started' &&
                  item.status != 'Incomplete' &&
                  item.active_user_id == null
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"icon-link",attrs:{"href":("/orgquizreport/" + (item.id)),"target":"_blank"}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("View report")])]):_vm._e(),(
                (item.status == 'In Progress' ||
                  item.status == 'started' ||
                  item.status == 'Incomplete') &&
                  item.active_user_id == null
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"icon-link",attrs:{"href":("/report/" + (item.id))}},'a',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("View report")])]):_vm._e(),(item.active_user_id != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"#ff0000"}},'v-icon',attrs,false),on),[_vm._v(" mdi-access-point")])]}}],null,true)},[_c('span',[_vm._v("On live editing")])]):_vm._e()]}},{key:"item.id_title",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" - "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "category"]))+" ")]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0 ",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"fix-detail-dashboard  rounded-0"},[_c('v-card-text',[_c('p',[_c('b',[_vm._v("Report ID:")]),_vm._v(" "+_vm._s(item.id))]),_c('p',[_c('b',[_vm._v("Report name:")]),_vm._v(" "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "title"]))+" ")]),_c('p',[_c('b',[_vm._v("Report category:")]),_vm._v(" "+_vm._s(_vm.getNestedObject(item, ["courseDetails", 0, "category"]))+" ")]),_c('p',[_c('b',[_vm._v("Submitted by:")]),_vm._v(" "+_vm._s(item.user.name))]),_c('p',[_c('b',[_vm._v("Review status:")]),_vm._v(" (you can't change this if the report is not in \"Submitted\" status ) ")]),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":['Approved', 'Rejected', 'Reviewing'],"label":"No status","dense":"","solo":"","disabled":item.status != 'Submitted'},on:{"change":function($event){return _vm.changeReviewStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)],1)],1)]}}],null,false,772067755)})],1)],1)],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading new data")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }