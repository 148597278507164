<template>
  <div class="dashboard-org">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col cols="12">
          <div v-if="currentUser" class="d-lg-flex align-center">
            <h1 class="text-h2 mr-3">Org Reports dashboard</h1>
          </div>

          <v-divider class="mt-8 mb-3"></v-divider>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">

          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="headers"
            :items="orgReports"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :sort-desc="[false, true]"
            multi-sort
            show-expand
            show-select
            class="elevation-5 mb-15 align-center"
            :search="search"
            :custom-filter="customDataTableItemsFilter"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
          >
            <template v-slot:item.risk="{ item }">
              <v-chip :color="getColor(item.final_risk_level)" dark>Low</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">


              <v-tooltip
                v-if="
                  item.status != 'In Progress' &&
                    item.status != 'started' &&
                    item.status != 'Incomplete' &&
                    item.active_user_id == null
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/orgquizreport/${item.id}`"
                    target="_blank"
                  >
                    <v-icon class="mr-2">mdi-file-document</v-icon>
                  </a>
                </template>
                <span>View report</span>
              </v-tooltip>

              <v-tooltip
                v-if="
                  (item.status == 'In Progress' ||
                    item.status == 'started' ||
                    item.status == 'Incomplete') &&
                    item.active_user_id == null
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/report/${item.id}`"
                  >
                    <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                  </a>
                </template>
                <span>View report</span>
              </v-tooltip>

              

              <v-tooltip v-if="item.active_user_id != null" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff0000" v-bind="attrs" v-on="on" class="mr-2">
                    mdi-access-point</v-icon
                  >
                </template>
                <span>On live editing</span>
              </v-tooltip>
            </template>

            <template #item.id_title="{ item }">
              {{ item.id }} -
              {{ getNestedObject(item, ["courseDetails", 0, "category"]) }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-0 ma-0 " :colspan="headers.length">
                <v-card class="fix-detail-dashboard  rounded-0">
                  <v-card-text>
                    <p><b>Report ID:</b> {{ item.id }}</p>
                    <p>
                      <b>Report name:</b>
                      {{ getNestedObject(item, ["courseDetails", 0, "title"]) }}
                    </p>
                    <p>
                      <b>Report category:</b>
                      {{
                        getNestedObject(item, ["courseDetails", 0, "category"])
                      }}
                    </p>
                    <p><b>Submitted by:</b> {{ item.user.name }}</p>
                    <p>
                      <b>Review status:</b> (you can't change this if the report
                      is not in "Submitted" status )
                    </p>
                    <v-select
                      v-model="item.status"
                      :items="['Approved', 'Rejected', 'Reviewing']"
                      label="No status"
                      dense
                      style="max-width: 300px"
                      solo
                      :disabled="item.status != 'Submitted'"
                      @change="changeReviewStatus(item)"
                    ></v-select>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ReportService from "@/services/report.service";
import LicenseService from "@/services/license.service";
import moment from "moment";

export default {
  name: "Generic",
  components: {
    Header
  },
  data() {
    return {
      showReviewStatusSnach: false,
      reviewStatusSelected: null,
      errorMergeModal: false,
      errorLabel: false,
      labelConsolidateReport: "",
      valueRules: [
        value => !!value || "Required.",
        value => (value && value.length >= 3) || "Min 3 characters"
      ],
      validConsolidateForm: true,
      isLoading: false,
      dialog: false,
      snackbar: false,
      valid: true,
      alert: "",
      dataLoading: true,
      options: {},
      options_cons: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      search: "",
      consReports: [],
      orgReports: [],
      userReport: [],
      expanded: [],
      errorConsolidate: "",
      singleExpand: true,
      statusList: ["Newly Submitted", "Approved", "Rejected", "Under Review"],
      headers_consolidate: [
        { text: "Report", value: "id_label" },
        { text: "Date submitted", value: "created_at" },
        // { text: "Worker group type", value: "wgtype.name" },
        // { text: "Worker group", value: "workergroup.name" },
        // { text: "Submitted by", value: "user.name" },
        { text: "Risk level", value: "risk" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],

      headers: [
        { text: "Report", value: "id_title" },
        { text: "Date submitted", value: "created_at" },
        { text: "Location", value: "location.name" },
        { text: "Worker group", value: "workergroup.name" },
        { text: "Risk level", value: "risk" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],

      headersModal: [
        { text: "ID", value: "id" },
        { text: "Date submitted", value: "created_at" },
        { text: "Submitted by", value: "user.name" },
        { text: "Risk level", value: "risk" }
      ],
      orgReportsModal: [
        {
          created_at: "2020-12-18 3:02 pm",
          id: 605,
          risk: "High",
          user: {
            name: "Demo Admin"
          }
        },
        {
          created_at: "2020-12-18 3:02 pm",
          id: 625,
          risk: "Medium",
          user: {
            name: "Demo Admin"
          }
        }
      ],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedReports();
        }
      },
      deep: true
    }
  },

  computed: {

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  methods: {
    getNestedObject(nestedObj, pathArr) {
      //Access Nested Objects Using Array Reduce // hackernoon
      return pathArr.reduce(
        (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
        nestedObj
      );
    },

    async initialize() {
      this.getMyLicenses();
      this.getPagedReports();
    },

    async getMyLicenses() {
      await LicenseService.getMyLicenses({});
    },
    async getPagedReports() {
      this.dataLoading = true;

      const orgId = this.$route.params.id;
      const { page } = this.options;

      const response = await ReportService.get({
        id: orgId,
        query: {
          page: page
        }
      });
      this.options.itemsPerPage = parseInt(response.data.meta.per_page);
      this.orgReports = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.formatReportDate();
      this.dataLoading = false;
    },

    async formatReportDate() {
      await this.consReports.map(rep => {
        return (rep.created_at = moment(rep.created_at).format("YYYY-MM-DD"));
      });

      await this.orgReports.map(rep => {
        return (rep.created_at = moment(rep.created_at).format("YYYY-MM-DD"));
      });
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    customDataTableItemsFilter(value, search, items) {
      /*
      Filter for individual words in search string. Filters
      all object values rather than just the keys included
      in the data table headers.
       */
      const wordArray = search
        .toString()
        .toLowerCase()
        .split(" ")
        .filter(x => x);
      return wordArray.every(word =>
        JSON.stringify(Object.values(items))
          .toString()
          .toLowerCase()
          .includes(word)
      );
    },
    getColor(risk) {
      if (risk == "High") return "error";
      else if (risk == "Moderate") return "warning";
      else return "success";
    },
    getWorkerGroupByReportId(reportId) {
      const report = this.reports.filter(report => report.id === reportId);
      return report[0].workerGroups;
    }
  }
};
</script>
<style lang="scss" scoped>
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
.resource-card {
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
